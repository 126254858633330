import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlogsCard from "./BlogsCard";
import DemoImg from "../../Assets/demo.jpg";
import Particle from "../Particle";

function BlogHome() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em"}}>
              <strong className="purple">BLOG YAZILARIM</strong>
            </h1>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <BlogsCard
              imgPath={DemoImg}
              isBlog={true}
              title="RadomeWEB"
              description="Yapımına Nisan 2022'de başlanan Verira ve Awm Bilişim firmaları için yapılmış 2 ayrı altyapı ile gelmektedir. Minecraft Java ve Bedrock sunucuları için PHP 8 destekli geniş özelliklere sahip bir webscript."
              ghLink="https://radome.web.tr/v1"
              demoLink="https://v1.radome.web.tr"
            />
          </Col>

          <Col md={4} className="project-card">
            <BlogsCard
              imgPath={DemoImg}
              isBlog={true}
              title="RadomePanel"
              description="Verira Bilişim Hizmetleri adına Temmuz 2021 yılında yapılmaya başlanan RadomePanel C# ile yazılmıştır. Minecraft sunucu yönetimi ve CDN üzerinden sunucu dosyası indirme gibi özelliklere sahiptir."
              ghLink="https://verira.com"
              demoLink="https://verira.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <BlogsCard
              imgPath={DemoImg}
              isBlog={true}
              title="ZoraPvP"
              description="Şubat 2021'de kurulan ZoraPvP bir topluluk sunucusu olması ile tanınmaktadır. An itibariyle 5700 kullanıcı ve 1500 aktiflik ile Türkiye'nin en büyük pvp topluluklarından bir tanesi olma özelliğine sahip."
              ghLink="https://zorapvp.xyz/"
              demoLink="https://zorapvp.xyz/"              
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default BlogHome;
